import {
    LOADING_SETTINGS,
    SET_AREA_MANAGER,
    SET_COMPANIES,
    SET_DIRECTIONS,
    SET_GEO_LOCATION,
    SET_HUBS,
    SET_ITEMS,
    SET_LISTS,
    SET_LOGS,
    SET_NOTIFICATIONS,
    SET_PAYMENT_ACCOUNTS,
    SET_PERMISSIONS,
    SET_PRICE_TIER_MANAGER,
    SET_ROLES,
    SET_SETTINGS,
    SET_SETTINGS_DEVICES,
    SET_SOURCES,
    SET_TAGS,
    SET_TASK_MANAGER,
    SET_THEME,
    SET_USER_ID_LOGS,
    SET_USERS,
    SET_VEHICLES,
} from "../actions/types";

const initState = {
    permissions: [],
    directions: [],
    vehicles: [],
    lists: [],
    users: [],
    roles: [],
    hubs: [],
    companies: [],
    geoLocations: [],
    items: [],
    logs: [],
    notifications: [],
    payment_accounts: [],
    tags: [],
    devices: [],
    sources: [],
    taskManager: null,
    userIdLogs: null,
    loading: true,
    serviceZones: [],
    theme: JSON.parse(localStorage.getItem("appSettings"))?.theme,
};

export const settingsReducer = (state = initState, action) => {
    switch (action.type) {
        case LOADING_SETTINGS:
            return {...state, loading: action.payload};
        case SET_LISTS:
            return {...state, lists: action.payload};
        case SET_ITEMS:
            return {...state, items: action.payload};
        case SET_USERS:
            return {...state, users: action.payload};
        case SET_ROLES:
            return {...state, roles: action.payload};
        case SET_HUBS:
            return {...state, hubs: action.payload};
        case SET_PERMISSIONS:
            return {...state, permissions: action.payload};
        case SET_COMPANIES:
            return {...state, companies: action.payload};
        case SET_VEHICLES:
            return {...state, vehicles: action.payload};
        case SET_DIRECTIONS:
            return {...state, directions: action.payload};
        case SET_GEO_LOCATION:
            return {...state, geoLocations: action.payload};
        case SET_LOGS:
            return {...state, logs: action.payload};
        case SET_USER_ID_LOGS:
            return {...state, userIdLogs: action.payload};
        case SET_NOTIFICATIONS:
            return {...state, notifications: action.payload};
        case SET_PAYMENT_ACCOUNTS:
            return {...state, payment_accounts: action.payload};
        case SET_TAGS:
            return {...state, tags: action.payload};
        case SET_SOURCES:
            return {...state, sources: action.payload};
        case SET_SETTINGS_DEVICES:
            return {...state, devices: action.payload};
        case SET_SETTINGS:
            return {...state, ...action.payload};
        case SET_THEME:
            return {...state, theme: action.payload};
        case SET_TASK_MANAGER:
            return {...state, taskManager: action.payload};
        case SET_AREA_MANAGER:
            return {...state, serviceZones: action.payload};
        case SET_PRICE_TIER_MANAGER:
            return {...state, priceTierManager: action.payload};
        default:
            return state;
    }
};
