import axios from "axios";
import {setAuthUser} from "../index";

const baseApiUrl =
    process.env.NODE_ENV === "production"
        ? "https://api.seagoat.app"
        : "https://api.seagoat.local";

export const baseHomeUrl =
    process.env.NODE_ENV === "production"
        ? "https://seagoat.app"
        : "https://seagoat.local";

export const core = axios.create({
    baseURL: baseApiUrl,
    withCredentials: true,
});

export const setInterceptors = () => {
    return (dispatch, getState) => {
        core.interceptors.request.use(
            (config) => {
                config.headers.Authorization = `Bearer ${localStorage.getItem(
                    "access_token"
                )}`;
                return config;
            },
            (error) => {
                //return Promise.reject(error);
            }
        );

        core.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        dispatch(setAuthUser(null));
                        window.location.href = `${baseHomeUrl}/login`;
                        // history.push("/login");
                        // dispatch(sessionOutcome("expired"));
                    } else if (error.response.status === 403) {
                        // dispatch(sessionOutcome("kicked"));
                    } else if (error.response.status === 405) {
                        // window.location.replace("/login");
                    }
                }
                return Promise.reject(error);
            }
        );
    };
};
