import {
  CHECK_ORDER_CLIENT_PROFILE,
  LOADING_APP,
  LOADING_CLIENTS,
  LOADING_IS_DAMAGED,
  LOADING_NOTIFICATIONS,
  LOADING_ORDER_THIRD_PARTY,
  LOADING_ORDER_UPDATE,
  LOADING_ORDER_VIEW,
  LOADING_ORDERS,
  LOADING_PAYMENTS,
  LOADING_PLANNER,
  LOADING_ROUTE_VIEW_BUTTON,
  LOADING_ROUTES,
  LOADING_SEARCH_ORDER,
  LOADING_SETTINGS,
  LOADING_SMS,
  LOADING_SMS_CHAT,
  LOADING_USER_SETTINGS,
  LOADING_VIEW,
  SET_AUTH_RESPONSE,
  SET_AUTH_USER,
  SET_CALENDAR_POP_UP,
  SET_CHAT,
  SET_CLIENT_PROFILE_SEARCH,
  SET_CLIENTS,
  SET_COMPANIES,
  SET_CURRENT_CLIENT,
  SET_CURRENT_DEVICE,
  SET_CURRENT_ORDER,
  SET_CURRENT_ORDER_WARNINGS,
  SET_CURRENT_ROUTE,
  SET_CURRENT_CHAT,
  SET_DEVICES,
  SET_DIRECTIONS,
  SET_GEO_LOCATION,
  SET_HUBS,
  SET_ITEMS,
  SET_LISTS,
  SET_LOADING_SEARCH,
  SET_LOGS,
  SET_NOTIFICATIONS,
  SET_ORDER_FILTER,
  SET_ORDERS,
  SET_PAYMENT_ACCOUNTS,
  SET_PAYMENTS,
  SET_PERMISSIONS,
  SET_PLANNED_ADVANCED_ROUTE,
  SET_PLANNED_CURRENT_ROUTE,
  SET_ROLES,
  SET_ROUTES,
  SET_SEARCH_ORDER,
  SET_SETTINGS,
  SET_SETTINGS_DEVICES,
  SET_SETTINGS_MENU_ACTIVE_KEY,
  SET_SLIDER_ORDER_DETAILS,
  SET_SOURCES,
  SET_TAGS,
  SET_THEME,
  SET_USER_ID_LOGS,
  SET_USERS,
  SET_VEHICLES,
  LOADING_SHIP_IT,
  SET_ACTIVE_ROUTE_SHIP_IT,
  SET_DONOR_SHIP_IT
} from "./types";

export const loadingShipIt = (payload) => ({
  type: LOADING_SHIP_IT,
  payload
});

export const setActiveRouteShipIt = (payload) => ({
  type: SET_ACTIVE_ROUTE_SHIP_IT,
  payload
});

export const setDonorShipIt = (payload) => ({
  type: SET_DONOR_SHIP_IT,
  payload
});

export const setTheme = (payload) => ({
  type: SET_THEME,
  payload
});

export const loadingView = (flag) => ({
  type: LOADING_VIEW,
  payload: flag
});

export const setPayments = (flag) => ({
  type: SET_PAYMENTS,
  payload: flag
});

export const loadingPayments = (flag) => ({
  type: LOADING_PAYMENTS,
  payload: flag
});

export const setSettingsDevices = (payload) => ({
  type: SET_SETTINGS_DEVICES,
  payload
});

export const setSettings = (payload) => ({
  type: SET_SETTINGS,
  payload
});

export const setOrderFilter = (payload) => ({
  type: SET_ORDER_FILTER,
  payload
});

export const loadingSearchOrders = (payload) => ({
  type: LOADING_SEARCH_ORDER,
  payload
});

export const setSearchOrders = (payload) => ({
  type: SET_SEARCH_ORDER,
  payload
});

export const setOrigins = (payload) => ({
  type: SET_SOURCES,
  payload
});

export const setPlannedCurrentRoute = (payload) => ({
  type: SET_PLANNED_CURRENT_ROUTE,
  payload
});

export const setPlannedAdvancedRoute = (payload) => ({
  type: SET_PLANNED_ADVANCED_ROUTE,
  payload
});

export const setLoadingPlanner = (values) => ({
  type: LOADING_PLANNER,
  payload: values
});

export const setTags = (values) => ({
  type: SET_TAGS,
  payload: values
});

export const setPaymentAccounts = (values) => ({
  type: SET_PAYMENT_ACCOUNTS,
  payload: values
});

export const setNotifications = (values) => ({
  type: SET_NOTIFICATIONS,
  payload: values
});

export const loadingNotifications = (flag) => ({
  type: LOADING_NOTIFICATIONS,
  payload: flag
});

export const setLoadingOrderUpdate = (payload) => ({
  type: LOADING_ORDER_UPDATE,
  payload
});

export const setItems = (payload) => ({
  type: SET_ITEMS,
  payload
});

export const setVehicles = (payload) => ({
  type: SET_VEHICLES,
  payload
});

export const setDirections = (payload) => ({
  type: SET_DIRECTIONS,
  payload
});

export const setSettingsMenuActiveKey = (payload) => {
  return {
    type: SET_SETTINGS_MENU_ACTIVE_KEY,
    payload
  };
};

export const setHubs = (payload) => ({
  type: SET_HUBS,
  payload
});

export const setUserIdLogs = (payload) => ({
  type: SET_USER_ID_LOGS,
  payload
});

export const setLogs = (payload) => ({
  type: SET_LOGS,
  payload
});

export const setGeoLocation = (flag) => ({
  type: SET_GEO_LOCATION,
  payload: flag
});

export const loadingSMSChat = (flag) => ({
  type: LOADING_SMS_CHAT,
  payload: flag
});

export const setChat = (chat) => ({
  type: SET_CHAT,
  payload: chat
});

export const setCurrentDevice = (device) => ({
  type: SET_CURRENT_DEVICE,
  payload: device
});

export const setCurrentChat = (smsChat) => ({
  type: SET_CURRENT_CHAT,
  payload: smsChat
});

export const setDevices = (devices) => ({
  type: SET_DEVICES,
  payload: devices
});

export const loadingUserSettings = (flag) => ({
  type: LOADING_USER_SETTINGS,
  payload: flag
});

export const loadingSMS = (flag) => ({
  type: LOADING_SMS,
  payload: flag
});

export const loadingClients = (flag) => ({
  type: LOADING_CLIENTS,
  payload: flag
});

export const loadingIsDamaged = (flag) => ({
  type: LOADING_IS_DAMAGED,
  payload: flag
});

export const setClients = (clients) => {
  return {
    type: SET_CLIENTS,
    payload: clients
  };
};

export const setCurrentClient = (client) => {
  return {
    type: SET_CURRENT_CLIENT,
    payload: client
  };
};

export const setCalendarPopUp = (popUpData) => {
  return {
    type: SET_CALENDAR_POP_UP,
    payload: popUpData
  };
};

export const setSliderDetails = (order) => {
  return {
    type: SET_SLIDER_ORDER_DETAILS,
    payload: order
  };
};

export const setRoles = (roles) => ({
  type: SET_ROLES,
  payload: roles
});

export function setAuthUser(user) {
  return {
    type: SET_AUTH_USER,
    payload: user
  };
}

export function setAuthResponse(auth) {
  return {
    type: SET_AUTH_RESPONSE,
    payload: auth
  };
}

export function loadingSettings(flag) {
  return {
    type: LOADING_SETTINGS,
    payload: flag
  };
}

export function setCompanies(orgs) {
  return {
    type: SET_COMPANIES,
    payload: orgs
  };
}

/**
 * Method sets lists
 *
 * @params {orders} array  Orders
 */
export function setPermission(lists) {
  return {
    type: SET_PERMISSIONS,
    payload: lists
  };
}

/**
 * Method sets lists
 *
 * @params {orders} array  Orders
 */
export function setLists(lists) {
  return {
    type: SET_LISTS,
    payload: lists
  };
}

/**
 * Method sets users
 *
 * @params {orders} array  Orders
 */
export function setUsers(users) {
  return {
    type: SET_USERS,
    payload: users
  };
}

/**
 * Method sets current order
 *
 * @params {orders} array  Orders
 */
export function setCurrentOrder(order) {
  return {
    type: SET_CURRENT_ORDER,
    payload: order
  };
}

export function setLoadingSearch(order) {
  return {
    type: SET_LOADING_SEARCH,
    payload: order
  };
}

export const setClientProfileSearchData = (flag) => {
  return {
    type: SET_CLIENT_PROFILE_SEARCH,
    payload: flag
  };
};

export function setOrderClientProfile(profile) {
  return {
    type: CHECK_ORDER_CLIENT_PROFILE,
    payload: profile
  };
}

/**
 * Method sets current order warnings
 *
 * @params {orders} array  Orders
 */
export function setCurrentOrderWarnings(warning) {
  return {
    type: SET_CURRENT_ORDER_WARNINGS,
    payload: warning
  };
}

/**
 * Method sets current route
 *
 * @params {orders} array  Orders
 */
export function setCurrentRoute(route) {
  return {
    type: SET_CURRENT_ROUTE,
    payload: route
  };
}

export function setLoadingRoute(flag) {
  return {
    type: LOADING_ROUTES,
    payload: flag
  };
}

export function setLoadingApp(flag) {
  return {
    type: LOADING_APP,
    payload: flag
  };
}

/**
 * Method sets routes
 *
 * @params {orders} array  Orders
 */
export function setRoutes(routes) {
  return {
    type: SET_ROUTES,
    payload: routes
  };
}

/**
 * Method sets routes
 *
 * @params {orders} array  Orders
 */
export function loadingRoutes(flag) {
  return {
    type: LOADING_ROUTES,
    payload: flag
  };
}

export function loadingOrderThirdParty(flag) {
  return {
    type: LOADING_ORDER_THIRD_PARTY,
    payload: flag
  };
}

/**
 * Method sets routes
 *
 * @params {orders} array  Orders
 */
export function loadingRouteViewButton(flag) {
  return {
    type: LOADING_ROUTE_VIEW_BUTTON,
    payload: flag
  };
}

/**
 * Method sets orders
 *
 * @params {orders} array  Orders
 */
export function setOrders(orders) {
  return {
    type: SET_ORDERS,
    payload: orders
  };
}

/**
 * Method sets orders
 *
 * @params {orders} array  Orders
 */
export function loadingOrders(flag) {
  return {
    type: LOADING_ORDERS,
    payload: flag
  };
}

/**
 * Method sets orders
 *
 * @params {orders} array  Orders
 */
export function loadingOrderViewForm(flag) {
  return {
    type: LOADING_ORDER_VIEW,
    payload: flag
  };
}
