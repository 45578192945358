import {LOADING_PAYMENTS, SET_PAYMENTS} from "../actions/types";

const initState = {
    listing: ['1'],
    loading: false,
}

export const paymentReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_PAYMENTS:
            return {...state, listing: action.payload}
        case LOADING_PAYMENTS:
            return {...state, loading: action.payload}
        default:
            return state;
    }
}







