import {
  LOADING_SMS,
  LOADING_SMS_CHAT,
  SET_CHAT,
  SET_CURRENT_DEVICE,
  SET_CURRENT_CHAT,
  SET_DEVICES
} from "../actions/types";

const initState = {
  loading: true,
  devices: [],
  current: undefined,
  loadingChat: true,
  currentChat: [],
  chat: []
};

export const smsReducer = (state = initState, action) => {
  switch (action.type) {
    case LOADING_SMS:
      return { ...state, loading: action.payload };
    case SET_DEVICES:
      return { ...state, devices: action.payload };
    case SET_CURRENT_CHAT:
      return { ...state, currentChat: action.payload };
    case SET_CURRENT_DEVICE:
      return { ...state, current: action.payload };
    case LOADING_SMS_CHAT:
      return { ...state, loadingChat: action.payload };
    case SET_CHAT:
      return { ...state, chat: action.payload };
    default:
      return state;
  }
};
