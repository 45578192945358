import {LOADING_PLANNER, SET_PLANNED_ADVANCED_ROUTE, SET_PLANNED_CURRENT_ROUTE} from "../actions/types";

const initState = {
    loading: false,
    advanced: null,
    current: null
};

export const plannerReducer = (state = initState, action) => {
    switch (action.type) {
        case LOADING_PLANNER:
            return {...state, loading: action.payload};
        case SET_PLANNED_CURRENT_ROUTE:
            return {...state, current: action.payload};
        case SET_PLANNED_ADVANCED_ROUTE:
            return {...state, advanced: action.payload};
        default:
            return state;
    }
};
