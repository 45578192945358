import {SET_CALENDAR_POP_UP} from "../actions/types";


const initState = {
    calendarPopUp: undefined,
}


export const logistReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_CALENDAR_POP_UP:
            return {...state, calendarPopUp: action.payload};
        default:
            return state;
    }
}