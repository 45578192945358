import {SET_LOADING_TASKS, SET_TASK, SET_TASKS} from "../actions/types";

const initState = {
    loading: false,
    listing: {},
    current: undefined,
};

export const taskReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_TASK:
            return {...state, current: action.payload};
        case SET_TASKS:
            return {...state, listing: action.payload};
        case SET_LOADING_TASKS:
            return {...state, loading: action.payload};
        default:
            return state;
    }
};
