import {
    loadingSettings,
    setAuthUser,
    setCompanies,
    setDirections,
    setGeoLocation,
    setHubs,
    setItems,
    setLists,
    setLogs,
    setOrigins,
    setPaymentAccounts,
    setPermission,
    setRoles,
    setSettings,
    setSettingsDevices,
    setTags,
    setUsers,
    setVehicles
} from "../../index";
import _ from "lodash";
import {core} from "../../axiosService";
import {confirmModal} from "../../../helpers/modal/Confirm";
// import { openDB } from "idb";

// const DB_NAME = "settingsDB";
// const STORE_NAME = "settings";
//
// const initDB = async () => {
//   return openDB(DB_NAME, 1, {
//     upgrade(db) {
//       db.createObjectStore(STORE_NAME);
//     }
//   });
// };

/**
 *
 */
export const getAppSettings = () => async (dispatch) => {
    // const db = await initDB();
    //
    // const cachedData = await db.get(STORE_NAME, "settings");
    // if (cachedData) {
    //   dispatch(setSettings(cachedData));
    //   return;
    // }
    dispatch(loadingSettings(true))
    core.get(`api/office/app-settings`).then(async (response) => {
        // const settings = response.data;

        // await db.put(STORE_NAME, settings, "settings");

        dispatch(setSettings(response.data));
    }).then(() => dispatch(loadingSettings(false)));
};

export const createOrigin = (vals) => (dispatch) => {
    core.post(`/api/sources`, {...vals}).then((response) => {
        dispatch(setOrigins(response.data.data));
    });
};

export const updateOrigin = (vals) => (dispatch) => {
    core.put(`/api/sources/${vals.id}`, {...vals}).then((response) => {
        dispatch(setOrigins(response.data.data));
    });
};

/**
 * Method deletes users list
 *
 * @params {payload} boolean  flag
 */
export const deleteOrigin = (vals) => (dispatch) => {
    core
        .delete(`/api/sources/${vals.id}`, {...vals})
        .then((response) => {
            dispatch(setOrigins(response.data.data));
        })
        .catch((request) => {
            confirmModal({
                open: true,
                title: request.response.data.error
            });
        });
};

export const getSources = () => (dispatch) => {
    core.get(`api/sources`).then((response) => {
        dispatch(setOrigins(response.data.data));
    });
};

export const getSettings = (user) => (dispatch) => {
    core.get(`api/settings/${user.licence.id}`).then((response) => {
        dispatch(setSettings(response.data));
    });
};

/**
 * Method gets users list
 *
 * @params {payload} boolean  flag
 */
export const getTags = () => (dispatch) => {
    dispatch(loadingSettings(true));
    core
        .get(`/api/tags`)
        .then((response) => dispatch(setTags(response.data.tags)))
        .then(() => dispatch(loadingSettings(false)));
};

/**
 * Method deletes users list
 *
 * @params {payload} boolean  flag
 */
export const deleteTag = (vals) => (dispatch, getState) => {
    let state = _.cloneDeep(getState());
    state.settings.tags = state.settings.tags.filter((tag) => {
        return tag.id !== vals.id;
    });
    dispatch(setTags(state.settings.tags));

    core.delete(`/api/tags/${vals.id}`, {...vals}).catch((request) => {
        confirmModal({
            open: true,
            title: request.response.data.error
        });
    });
};

export const createTag = (vals) => (dispatch) => {
    core.post(`/api/tags`, {...vals}).then((response) => {
        dispatch(setTags(response.data.tags));
    });
};

export const updateTag = (vals) => (dispatch) => {
    core.put(`/api/tags/${vals.id}`, {...vals}).then((response) => {
        dispatch(setTags(response.data.tags));
    });
};

/**
 * Method gets users list
 *
 * @params {payload} boolean  flag
 */
export const getPaymentAccounts = () => (dispatch) => {
    dispatch(loadingSettings(true));
    core
        .get(`/api/payment_account`)
        .then((response) =>
            dispatch(setPaymentAccounts(response.data.payment_accounts))
        )
        .then(() => dispatch(loadingSettings(false)));
};

/**
 * Method deletes users list
 *
 * @params {payload} boolean  flag
 */
export const deletePaymentAccount = (vals) => (dispatch) => {
    core
        .delete(`/api/payment_account/${vals.id}`, {...vals})
        .then((response) => {
            dispatch(setPaymentAccounts(response.data.payment_accounts));
        })
        .catch((request) => {
            confirmModal({
                open: true,
                title: request.response.data.error
            });
        });
};

export const createPaymentAccount = (vals) => (dispatch) => {
    core.post(`/api/payment_account`, {...vals}).then((response) => {
        dispatch(setPaymentAccounts(response.data.payment_accounts));
    });
};

export const updatePaymentAccount = (vals) => (dispatch) => {
    core.put(`/api/payment_account/${vals.id}`, {...vals}).then((response) => {
        dispatch(setPaymentAccounts(response.data.payment_accounts));
    });
};

export const createItem = (vals) => (dispatch, getState) => {
    let state = _.cloneDeep(getState());
    core.post(`/api/items`, {...vals}).then((response) => {
        state.settings.items.unshift(response.data);
        dispatch(setItems(state.settings.items));
    });
};

export const updateItem = (vals) => (dispatch, getState) => {
    let state = _.cloneDeep(getState());

    state.settings.items = state.settings.items.map((item) => {
        if (item.id === vals.id) {
            return {...item, ...vals};
        }
        return item;
    });

    dispatch(setItems(state.settings.items));
    core.put(`/api/items/${vals.id}`, {...vals});
};

export const deleteItems = (vals) => (dispatch, getState) => {
    let state = _.cloneDeep(getState());
    state.settings.items = state.settings.items.filter((item) => {
        return item.id !== vals.id;
    });
    dispatch(setItems(state.settings.items));
    core.delete(`/api/items/${vals.id}`, {...vals});
};

export const getItems =
    (page = null) =>
        (dispatch) => {
            dispatch(loadingSettings(true));
            core
                .get(`/api/items`, {params: {page}})
                .then((response) => {
                    dispatch(setItems(response.data.items));
                })
                .finally(() => dispatch(loadingSettings(false)));
        };

export const deleteVehicle = (vals) => (dispatch, getState) => {
    let state = _.cloneDeep(getState());
    state.settings.vehicles.vehicles = state.settings.vehicles.vehicles.filter(
        (vehicle) => {
            return vehicle.id !== vals.id;
        }
    );
    dispatch(setVehicles(state.settings.vehicles));
    core.delete(`/api/vehicles/${vals.id}`, {...vals});
};

export const updateVehicle = (vals) => (dispatch, getState) => {
    let state = _.cloneDeep(getState());

    state.settings.vehicles.vehicles = state.settings.vehicles.vehicles.map(
        (vehicle) => {
            if (vehicle.id === vals.id) {
                return {...vehicle, ...vals};
            } else {
                return vehicle;
            }
        }
    );

    dispatch(setVehicles(state.settings.vehicles));
    core.put(`/api/vehicles/${vals.id}`, {...vals});
};

export const createVehicle = (vals) => (dispatch, getState) => {
    let state = _.cloneDeep(getState());
    dispatch(loadingSettings(true));
    core
        .post(`/api/vehicles`, {...vals})
        .then((response) => {
            if (_.isNil(state.settings.vehicles.vehicles)) {
                state.settings.vehicles.vehicles = [];
            }
            state.settings.vehicles.vehicles.unshift(response.data);
            dispatch(setVehicles(state.settings.vehicles));
        })
        .finally(() => dispatch(loadingSettings(false)));
};

export const getVehicles =
    (page = 1) =>
        (dispatch) => {
            dispatch(loadingSettings(true));
            core
                .get(`/api/vehicles`, {params: {page}})
                .then((response) => {
                    dispatch(setVehicles(response.data));
                })
                .finally(() => dispatch(loadingSettings(false)));
        };

export const deleteDirection = (vals) => (dispatch, getState) => {
    let state = _.cloneDeep(getState());
    state.settings.directions = state.settings.directions.filter((direction) => {
        return direction.id !== vals.id;
    });
    dispatch(setDirections(state.settings.directions));
    core.delete(`/api/directions/${vals.id}`, {...vals});
};

export const updateDirection = (vals) => (dispatch, getState) => {
    let state = _.cloneDeep(getState());
    state.settings.directions = state.settings.directions.map((direction) => {
        if (direction.id === vals.id) {
            return {...direction, ...vals};
        } else {
            return direction;
        }
    });
    dispatch(setDirections(state.settings.directions));
    core.put(`/api/directions/${vals.id}`, {...vals});
};

export const createDirection = (vals) => (dispatch, getState) => {
    let state = _.cloneDeep(getState());
    dispatch(loadingSettings(true));
    core
        .post(`/api/directions`, {...vals})
        .then((response) => {
            if (_.isNil(state.settings.directions)) {
                state.settings.directions = [];
            }
            state.settings.directions.unshift(response.data);
            dispatch(setDirections(state.settings.directions));
        })
        .finally(() => dispatch(loadingSettings(false)));
};

export const getDirections = () => (dispatch) => {
    dispatch(loadingSettings(true));
    core
        .get(`/api/directions`)
        .then((response) => dispatch(setDirections(response.data.directions)))
        .finally(() => dispatch(loadingSettings(false)));
};

export const deleteHub = (vals) => (dispatch, getState) => {
    let state = _.cloneDeep(getState());
    state.settings.hubs = state.settings.hubs.filter((hubs) => {
        return hubs.id !== vals.id;
    });
    dispatch(setHubs(state.settings.hubs));
    core.delete(`/api/hubs/${vals.id}`, {...vals});
};

export const updateHub = (vals) => (dispatch, getState) => {
    let state = _.cloneDeep(getState());
    state.settings.hubs = state.settings.hubs.map((hubs) => {
        if (hubs.id === vals.id) {
            return {...hubs, ...vals};
        } else {
            return hubs;
        }
    });
    dispatch(setHubs(state.settings.hubs));
    core.put(`/api/hubs/${vals.id}`, {...vals});
};

export const createHub = (vals) => (dispatch, getState) => {
    let state = _.cloneDeep(getState());
    dispatch(loadingSettings(true));
    core
        .post(`/api/hubs`, {...vals})
        .then((response) => {
            state.settings.hubs.unshift(response.data);
            dispatch(setHubs(state.settings.hubs));
        })
        .finally(() => dispatch(loadingSettings(false)));
};

export const getHubs =
    (page = 1) =>
        (dispatch) => {
            dispatch(loadingSettings(true));
            core
                .get(`/api/hubs`, {params: {page}})
                .then((response) => dispatch(setHubs(response.data.hubs)))
                .finally(() => dispatch(loadingSettings(false)));
        };

export const getAuditLogs =
    (page = 1, userIdLogs) =>
        (dispatch) => {
            dispatch(loadingSettings(true));
            core
                .get(`/api/logs`, {params: {page, user_id: userIdLogs}})
                .then((response) => dispatch(setLogs(response.data)))
                .finally(() => dispatch(loadingSettings(false)));
        };

export const deleteGeoLocation = (vals) => (dispatch, getState) => {
    let state = _.cloneDeep(getState());
    state.settings.geoLocations.geo_locations =
        state.settings.geoLocations.geo_locations.filter((location) => {
            return location.id !== vals.id;
        });
    dispatch(setGeoLocation(state.settings.geoLocations));
    core.delete(`/api/geo-locations/${vals.id}`, {...vals});
};

export const updateGeoLocation = (vals) => (dispatch) => {
    core.put(`/api/geo-locations/${vals.id}`, {...vals}).then((r) => {
        dispatch(setGeoLocation(r.data));
    });
};

export const createGeoLocation = (vals) => (dispatch) => {
    dispatch(loadingSettings(true));
    core
        .post(`/api/geo-locations`, {...vals})
        .then((response) => {
            dispatch(setGeoLocation(response.data));
        })
        .finally(() => dispatch(loadingSettings(false)));
};

export const getGeoLocation =
    (page = 1) =>
        (dispatch) => {
            dispatch(loadingSettings(true));
            core
                .get(`/api/geo-locations`, {params: {page}})
                .then((response) => dispatch(setGeoLocation(response.data)))
                .finally(() => dispatch(loadingSettings(false)));
        };

export const deleteCompany = (vals) => (dispatch, getState) => {
    core
        .delete(`/api/companies/${vals.id}`, {...vals})
        .then(() => {
            let state = _.cloneDeep(getState());
            state.settings.company = state.settings.companies.filter((company) => {
                return company.id !== vals.id;
            });
            dispatch(setCompanies(state.settings.company));
        })
        .catch((request) => {
            confirmModal({
                open: true,
                title: request.response.data.error
            });

            // dispatch(setCompanies(response));
        });
};

export const updateCompany = (vals) => (dispatch, getState) => {
    let state = _.cloneDeep(getState());
    state.settings.companies = state.settings.companies.map((company) => {
        if (company.id === vals.id) {
            return {...company, ...vals};
        } else {
            return company;
        }
    });
    dispatch(setCompanies(state.settings.companies));
    core.put(`/api/companies/${vals.id}`, {...vals});
};

export const createCompany = (vals) => (dispatch, getState) => {
    let state = _.cloneDeep(getState());
    dispatch(loadingSettings(true));
    core
        .post(`/api/companies`, {...vals})
        .then((response) => {
            state.settings.companies.unshift(response.data);
            dispatch(setCompanies(state.settings.companies));
        })
        .finally(() => dispatch(loadingSettings(false)));
};

/**
 * Method gets orders list
 *
 * @params {payload} boolean  flag
 */
export const getCompanies = () => (dispatch) => {
    dispatch(loadingSettings(true));
    core
        .get(`/api/companies`)
        .then((response) => dispatch(setCompanies(response.data.companies)))
        .then(() => dispatch(loadingSettings(false)));
};

export const deleteDevice = (vals) => (dispatch, getState) => {
    core
        .delete(`/api/devices/${vals.id}`, {...vals})
        .then(() => {
            let state = _.cloneDeep(getState());
            state.settings.devices = state.settings.devices.filter((device) => {
                return device.id !== vals.id;
            });
            dispatch(setSettingsDevices(state.settings.devices));
        })
        .catch((request) => {
            confirmModal({
                open: true,
                title: request.response.data.error
            });

            // dispatch(setCompanies(response));
        });
};

export const updateDevice = (vals) => (dispatch, getState) => {
    let state = _.cloneDeep(getState());
    state.settings.devices = state.settings.devices.map((device) => {
        if (device.id === vals.id) {
            return {...device, ...vals};
        } else {
            return device;
        }
    });
    dispatch(setSettingsDevices(state.settings.devices));
    core.put(`/api/devices/${vals.id}`, {...vals});
};

export const createDevice = (vals) => (dispatch, getState) => {
    let state = _.cloneDeep(getState());
    dispatch(loadingSettings(true));
    core
        .post(`/api/devices`, {...vals})
        .then((response) => {
            state.settings.devices.unshift(response.data);
            dispatch(setSettingsDevices(state.settings.devices));
        })
        .finally(() => dispatch(loadingSettings(false)));
};

/**
 * Method gets orders list
 *
 * @params {payload} boolean  flag
 */
export const getDevices = () => (dispatch) => {
    dispatch(loadingSettings(true));
    core
        .get(`/api/devices`)
        .then((response) => dispatch(setSettingsDevices(response.data)))
        .then(() => dispatch(loadingSettings(false)));
};

/**
 * Method gets orders list
 *
 * @params {payload} boolean  flag
 */
export const getLists = () => (dispatch) => {
    dispatch(loadingSettings(true));
    core
        .get(`/api/lists`)
        .then((response) => {
            dispatch(setLists(response.data.lists));
        })
        .then(() => dispatch(loadingSettings(false)));
};

/**
 * Method gets orders list
 *
 * @params {payload} boolean  flag
 */
export const updateList = (values) => (dispatch, getState) => {
    const state = _.cloneDeep(getState());
    core.put(`/api/lists/${values.id}`, values).then((response) => {
        const lists = state.settings.lists.map((list) => {
            if (list.id === response.data.list?.id) {
                list = {...list, ...response.data.list};
                // dispatch(showListItemModal(list))
            }

            return list;
        });
        dispatch(setLists(lists));
    });
};

/**
 * Method deletes users list
 *
 * @params {payload} boolean  flag
 */
export const deleteList = (vals) => (dispatch, getState) => {
    core
        .delete(`/api/lists/${vals.id}`, {...vals})
        .then(() => {
            let state = _.cloneDeep(getState());
            state.settings.lists = state.settings.lists.filter((list) => {
                return list.id !== vals.id;
            });
            dispatch(setLists(state.settings.lists));
        })
        .catch((request) => {
            confirmModal({
                open: true,
                title: request.response.data.error ?? "test"
            });
        });
};

/**
 * Method gets users list
 *
 * @params {payload} boolean  flag
 */
export const getUsers =
    (roleType = {}) =>
        (dispatch) => {
            dispatch(loadingSettings(true));
            core
                .get(`/api/users`, {params: roleType})
                .then((response) => dispatch(setUsers(response.data.users)))
                .then(() => dispatch(loadingSettings(false)));
        };

/**
 * Method gets lists all drivers
 *
 * @params {payload} boolean  flag
 */
export const getDrivers = () => (dispatch) => {
    dispatch(loadingSettings(true));
    core
        .get(`/api/users`)
        .then((response) => {
            dispatch(setUsers(response.data.data));
        })
        .finally(() => dispatch(loadingSettings(false)));
};

/**
 * Method deletes users list
 *
 * @params {payload} boolean  flag
 */
export const deleteUser = (vals) => (dispatch) => {
    core
        .delete(`/api/users/${vals.id}`, {...vals})
        .then((response) => {
            dispatch(setUsers(response.data.users));
        })
        .catch((request) => {
            confirmModal({
                open: true,
                title: request.response?.data.error || "Error"
            });
        });
};

export const createUser = (vals) => (dispatch) => {
    core.post(`/api/users`, {...vals}).then((response) => {
        // state.settings.users.unshift(response.data);
        dispatch(setUsers(response.data.users));
    });
};

export const updateUser = (vals) => (dispatch, getState) => {
    let state = _.cloneDeep(getState());
    const role = state.settings.roles.find((r) => vals.role_id === r.id);

    let users = state.settings.users.map((user) => {
        if (user.id === vals.id) {
            user.role = role;
            return {...user, ...vals};
        } else {
            return user;
        }
    });

    dispatch(setUsers(users));

    core.put(`/api/users/${vals.id}`, {...vals});
};

export const createRole = (vals) => (dispatch, getState) => {
    let state = _.cloneDeep(getState());
    core.post(`/api/roles`, {...vals}).then((response) => {
        state.settings.roles.unshift(response.data.roles);
        dispatch(setRoles(response.data.roles));
    });
};

export const updateRole = (vals) => (dispatch, getState) => {
    let state = _.cloneDeep(getState());

    core.put(`/api/roles/${vals.id}`, {...vals}).then((response) => {
        dispatch(setRoles(response.data.roles));
        // update current user role
        let currentUserRole = response.data.roles
            .filter((user) => user.id === state.auth.current.role_id)
            .reduce((current, role) => role, null);

        if (currentUserRole) {
            state.auth.current.role = currentUserRole;
            dispatch(setAuthUser(state.auth.current));
        }
    });
};

export const getRoles = () => (dispatch) => {
    dispatch(loadingSettings(true));
    core
        .get(`/api/roles`)
        .then((response) => dispatch(setRoles(response.data.roles)))
        .then(() => dispatch(loadingSettings(false)));
};

export const deleteRole = (vals) => (dispatch, getState) => {
    core
        .delete(`/api/roles/${vals.id}`, {...vals})
        .then(() => {
            let state = _.cloneDeep(getState());
            state.settings.roles = state.settings.roles.filter((role) => {
                return role.id !== vals.id;
            });
            dispatch(setRoles(state.settings.roles));
        })
        .catch((request) => {
            confirmModal({
                open: true,
                title: request.response.data.error
            });
        });
};

export const getPermission = () => (dispatch) => {
    dispatch(loadingSettings(true));
    core
        .get(`/api/permissions`)
        .then((response) => dispatch(setPermission(response.data.permissions)))
        .then(() => dispatch(loadingSettings(false)));
};
