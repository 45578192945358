import {theme} from "antd";

export const VERSION = "0.0.72 BETA";

export const darkTheme = {
    algorithm: [theme.darkAlgorithm],
    components: {
        Menu: {
            colorPrimary: "#535437",
            algorithm: true, // Enable algorithm
        },
        Select: {
            colorBgElevated: "#293033",
        }
    },
    token: {
        type: "dark",
        borderRadius: 2,
        colorBgBase: "#131719",
        colorBgElevated: "#1f1f1f",
        // "colorBgContainer": "#131719",
        // "colorBgLayout": "#e0e0dd", content

        colorPrimary: "#445cc1",
        colorSuccess: "#a2e87f",
        colorWarning: "#deb86c",
        colorError: "#a53335",
        colorTextSecondary: "blue",
        colorInfo: "#89b7f7",
        inputBgColor: "#212325",
        colorBgMask: "rgba(0, 0, 0, 0.45)",
        colorBgSpotlight: "rgba(0, 0, 0, 0.85)",
        colorBgTextActive: "rgba(0, 0, 0, 0.15)",
        colorBgTextHover: "rgba(0, 0, 0, 0.06)",
        colorBorder: "#181e28",
        colorText: "#eaecbe",
        // "colorTextBase": "#eaecbe",
        colorTextDisabled: "#eaecbe",
        colorTextHeading: "#eaecbe",
        colorTextLabel: "green",
        colorTextLightSolid: "#fff",
        colorTextPlaceholder: "rgba(230, 230, 230, 0.33)",
        colorTextQuaternary: "rgba(234, 236, 190, 0.8)", // input color
    },
};

export const lightTheme = {
    algorithm: [theme.defaultAlgorithm],
    token: {
        type: "light",
        borderRadius: 2,
        // "borderRadiusLG": 0,
        // "borderRadiusOuter": 0,
        // "borderRadiusSM": 0,
        // "borderRadiusXS": 0,
        colorBgBase: "#eaeae8",
        colorBgContainer: "#eaeae8",
        colorBgContainerDisabled: "rgba(0, 0, 0, 0.04)",
        colorBgElevated: "#eaeae8",
        colorBgLayout: "#e0e0dd",
        colorBgMask: "rgba(0, 0, 0, 0.45)",
        colorBgSpotlight: "rgba(0, 0, 0, 0.85)",
        colorBgTextActive: "rgba(0, 0, 0, 0.15)",
        colorBgTextHover: "rgba(0, 0, 0, 0.06)",
        colorBorder: "#c5c5c0",
        colorBorderBg: "#eaeae8",
        colorBorderSecondary: "#dbdbd8",
        colorError: "#a53335",
        colorErrorActive: "#802126",
        colorErrorBg: "#e6dad8",
        colorErrorBgHover: "#d9cdcc",
        colorErrorBorder: "#cca5a1",
        colorErrorBorderHover: "#bf7c78",
        colorErrorHover: "#b35554",
        colorErrorOutline: "rgba(177, 5, 3, 0.07)",
        colorErrorText: "#a53335",
        colorErrorTextActive: "#802126",
        colorErrorTextHover: "#b35554",
        colorFill: "rgba(0, 0, 0, 0.15)",
        colorFillAlter: "rgba(0, 0, 0, 0.02)",
        colorFillContent: "rgba(0, 0, 0, 0.06)",
        colorFillContentHover: "rgba(0, 0, 0, 0.15)",
        colorFillQuaternary: "rgba(0, 0, 0, 0.02)",
        colorFillSecondary: "rgba(0, 0, 0, 0.06)",
        colorFillTertiary: "rgba(0, 0, 0, 0.04)",
        colorHighlight: "#a53335",
        colorIcon: "rgba(232,15,15,0.45)",
        colorIconHover: "rgba(206,50,50,0.88)",
        colorInfo: "#426da9",
        colorInfoActive: "#2c4d82",
        colorInfoBg: "#dae2e8",
        colorInfoBgHover: "#ced5db",
        colorInfoBorder: "#b4c2cf",
        colorInfoBorderHover: "#8aa5c2",
        colorInfoHover: "#8aa5c2",
        colorInfoText: "#426da9",
        colorInfoTextActive: "#2c4d82",
        colorInfoTextHover: "#6488b5",
        colorLink: "#426da9",
        colorLinkActive: "#2c4d82",
        colorLinkHover: "#8aa5c2",
        colorPrimary: "#445cc1",
        colorPrimaryActive: "#2f419c",
        colorPrimaryBg: "#f0f5ff",
        colorPrimaryBgHover: "#e6ebf5",
        colorPrimaryBorder: "#c1cce8",
        colorPrimaryBorderHover: "#93a6db",
        colorPrimaryHover: "#6981cf",
        colorPrimaryText: "#445cc1",
        colorPrimaryTextActive: "#2f419c",
        colorPrimaryTextHover: "#6981cf",
        colorSplit: "rgba(20, 20, 3, 0.07)",
        colorSuccess: "#67ad44",
        colorSuccessActive: "#49872e",
        colorSuccessBg: "#e6eddf",
        colorSuccessBgHover: "#d9e0d3",
        colorSuccessBorder: "#c4d4b8",
        colorSuccessBorderHover: "#a4c78d",
        colorSuccessHover: "#a4c78d",
        colorSuccessText: "#67ad44",
        colorSuccessTextActive: "#49872e",
        colorSuccessTextHover: "#85ba66",
        colorText: "rgba(21,20,20,0.88)",
        colorTextBase: "#000000",
        colorTextDescription: "rgba(0, 0, 0, 0.45)",
        colorTextDisabled: "rgba(0, 0, 0, 0.25)",
        colorTextHeading: "rgba(0, 0, 0, 0.88)",
        colorTextLabel: "rgba(0, 0, 0, 0.65)",
        colorTextLightSolid: "#fff",
        colorTextPlaceholder: "rgba(0, 0, 0, 0.25)",
        colorTextQuaternary: "rgba(0, 0, 0, 0.25)",
        colorTextSecondary: "rgba(0, 0, 0, 0.65)",
        colorTextTertiary: "rgba(0, 0, 0, 0.45)",
        colorWarning: "#ca9835",
        colorWarningActive: "#a37422",
        colorWarningBg: "#fffcf0",
        colorWarningBgHover: "#fcf7e3",
        colorWarningBorder: "#f0e1b1",
        colorWarningBorderHover: "#e3ca84",
        colorWarningHover: "#e3ca84",
        colorWarningOutline: "rgba(255, 252, 240, 0.98)",
        colorWarningText: "#ca9835",
        colorWarningTextActive: "#a37422",
        colorWarningTextHover: "#d6b15a",
        colorWhite: "#fff",
        controlHeight: 32,
        controlHeightLG: 40,
        controlHeightSM: 24,
        controlHeightXS: 16,
        controlInteractiveSize: 16,
        controlItemBgActive: "#f0f5ff",
        controlItemBgActiveDisabled: "rgba(0, 0, 0, 0.15)",
        controlItemBgActiveHover: "#e6ebf5",
        controlItemBgHover: "rgba(0, 0, 0, 0.04)",
        controlOutline: "rgba(240, 245, 255, 0.98)",
        controlOutlineWidth: 2,
        controlPaddingHorizontal: 12,
        controlPaddingHorizontalSM: 8,
        controlTmpOutline: "rgba(0, 0, 0, 0.02)",
        fontSize: 14,
        fontSizeHeading1: 38,
        fontSizeHeading2: 30,
        fontSizeHeading3: 24,
        fontSizeHeading4: 20,
        fontSizeHeading5: 16,
        fontSizeIcon: 12,
        fontSizeLG: 16,
        fontSizeSM: 12,
        fontSizeXL: 20,
        fontWeightStrong: 600,
        lineHeight: 1.5714285714285714,
        lineHeightHeading1: 1.2105263157894737,
        lineHeightHeading2: 1.2666666666666666,
        lineHeightHeading3: 1.3333333333333333,
        lineHeightHeading4: 1.4,
        lineHeightHeading5: 1.5,
        lineHeightLG: 1.5,
        lineHeightSM: 1.6666666666666667,
        lineType: "solid",
        lineWidth: 1,
        lineWidthBold: 2,
        linkDecoration: "none",
        linkFocusDecoration: "none",
        linkHoverDecoration: "none",
        margin: 16,
        marginLG: 24,
        marginMD: 20,
        marginSM: 12,
        marginXL: 32,
        marginXS: 8,
        marginXXL: 48,
        marginXXS: 4,
        motionBase: 0,
        motionDurationFast: "0.1s",
        motionDurationMid: "0.2s",
        motionDurationSlow: "0.3s",
        motionEaseInBack: "cubic-bezier(0.71, -0.46, 0.88, 0.6)",
        motionEaseInOut: "cubic-bezier(0.645, 0.045, 0.355, 1)",
        motionEaseInOutCirc: "cubic-bezier(0.78, 0.14, 0.15, 0.86)",
        motionEaseInQuint: "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
        motionEaseOut: "cubic-bezier(0.215, 0.61, 0.355, 1)",
        motionEaseOutBack: "cubic-bezier(0.12, 0.4, 0.29, 1.46)",
        motionEaseOutCirc: "cubic-bezier(0.08, 0.82, 0.17, 1)",
        motionEaseOutQuint: "cubic-bezier(0.23, 1, 0.32, 1)",
        motionUnit: 0.1,
        opacityImage: 1,
        opacityLoading: 0.65,
        padding: 16,
        paddingContentHorizontal: 16,
        paddingContentHorizontalLG: 24,
        paddingContentHorizontalSM: 16,
        paddingContentVertical: 12,
        paddingContentVerticalLG: 16,
        paddingContentVerticalSM: 8,
        paddingLG: 24,
        paddingMD: 20,
        paddingSM: 12,
        paddingXL: 32,
        paddingXS: 8,
        paddingXXS: 4,
        screenLG: 992,
        screenLGMax: 1199,
        screenLGMin: 992,
        screenMD: 768,
        screenMDMax: 991,
        screenMDMin: 768,
        screenSM: 576,
        screenSMMax: 767,
        screenSMMin: 576,
        screenXL: 1200,
        screenXLMax: 1599,
        screenXLMin: 1200,
        screenXS: 480,
        screenXSMax: 575,
        screenXSMin: 480,
        screenXXL: 1600,
        screenXXLMin: 1600,
        size: 16,
        sizeLG: 24,
        sizeMD: 20,
        sizeMS: 16,
        sizePopupArrow: 16,
        sizeSM: 12,
        sizeStep: 4,
        sizeUnit: 4,
        sizeXL: 32,
        sizeXS: 8,
        sizeXXL: 48,
        sizeXXS: 4,
        wireframe: false,
        zIndexBase: 0,
        zIndexPopupBase: 1000,
    },
};

