import {Modal} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";

const {confirm} = Modal;

export const confirmModal = ({
                                 title,
                                 icon,
                                 content,
                                 action,
                                 okType,
                                 cancelText,
                                 okText,
                             }) => {
    confirm({
        title: title ?? "Do you want to delete this item?",
        icon: icon ?? <ExclamationCircleOutlined/>,
        contentBg: "red",
        content: content ?? "",
        okType: okType ?? "primary",
        cancelText: cancelText ?? "No",
        okText: okText ?? "Yes",
        onOk: () => {
            !action ? console.info("ok") : action();
        },
        onCancel: () => {
        },
    });
};

// const Confirm = ({confirmModal, setConfirmModal}) => {
//     const {visible, title, onOk, content} = confirmModal;
//
//     const onCancel = () => {
//         setConfirmModal({open: false})
//     }
//
//     return <ModalProxyOld {...{
//         title,
//         visible,
//         content,
//         onCancel,
//         onOk,
//     }}
//     />;
//
// }
//
// const mapStateToProps = (state) => {
//     return {
//         confirmModal: state.modal.confirmModal
//     }
// }
//
// const mapDispatchToProps = (dispatch) => {
//     return {
//         setConfirmModal: (data) => dispatch(setConfirmModal(data)),
//     }
// }
//
// export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
