import {loadingSettings, setAuthUser, setLoadingApp,} from "../index";
import {baseHomeUrl, core} from "../axiosService";
import history from "../../helpers/history";

import {
    getAppSettings,
    getDirections,
    getDrivers,
    getHubs,
    getItems,
    getLists,
    getRoles,
} from "../officeServices/settingsService";
import _ from "lodash";
// import { getNotifications } from "../officeServices/notificationService";
import {openDB} from "idb";

const DB_NAME = "settingsDB";
const STORE_NAME = "settings";

const initDB = async () => {
    return openDB(DB_NAME, 1, {
        upgrade(db) {
            db.createObjectStore(STORE_NAME);
        }
    });
};

export const getCurrentUser = () => (dispatch) => {
    dispatch(setLoadingApp(true));

    core
        .get(`api/getCurrentUser`)
        .then((response) => {
            if (response.data.user) {
                dispatch(setAuthUser(response.data.user));
                // dispatch(getCrucialData());
                dispatch(getAppSettings());
                // dispatch(getDirections());
                // dispatch(getLists());
                // dispatch(getItems());
                // dispatch(getVehicles());
                // dispatch(getHubs());
                // dispatch(getAuditLogs());
                // dispatch(getGeoLocation());
                // dispatch(getCompanies());
                // dispatch(getUsers());
                // dispatch(getRoles());
                // dispatch(getPermission());
                //************//
            }
        })
        .catch(() => {
            const currentUrl = window.location.href;
            window.location.href = `${baseHomeUrl}/login${
                !currentUrl ? "" : `?r=${encodeURL(currentUrl)}`
            }`;
        })
        .finally(() => dispatch(setLoadingApp(false)));
};

function encodeURL(url) {
    // Encode the URL using base64
    const encodedURL = btoa(url);

    // Encode the URL using encodeURIComponent
    return encodeURIComponent(encodedURL);
}

export const confirmPass = (values) => (dispatch, getState) => {
    const state = _.cloneDeep(getState());
    values.session = state.auth.response.session;
    values.username = state.auth.response.email;
    core.post(`api/login/confirm`, values).then(() => {
    });
};

/**
 * Authentication handler
 * @param values
 */
export const login = (values) => async (dispatch) => {
    const db = await initDB();
    db.clear();

    dispatch(setLoadingApp(true));
    core
        .post(`api/login`, values)
        .then((response) => {
            if (response.status === 201) {
                localStorage.setItem("access_token", response.data.token);
                localStorage.setItem("loggedIn", "true");
                dispatch(getAppSettings());

                dispatch(setAuthUser(response.data.user));
                history.push("/");
            }
        })
        .then(() => {
        })
        .finally(() => {
            dispatch(setLoadingApp(false));
        })
        .catch(() => {
            window.location.href = `${baseHomeUrl}/login`;
        });
};

export const logout = () => async (dispatch) => {
    const db = await initDB();

    await db.clear("settings");
    dispatch(setLoadingApp(true));
    core.get("/sanctum/csrf-cookie").then(() => {
        core.post(`api/logout`).then((response) => {
            dispatch(setAuthUser(null));
            localStorage.setItem("access_token", null);
            localStorage.setItem("loggedIn", "false");
            dispatch(setLoadingApp(false));
            window.location.href = response.data.redirect_to;
        });
    });
};

/**
 *  Loads all system/crucial data
 */
export const getCrucialData = () => (dispatch) => {
    return Promise.all([
        dispatch(getDirections()),
        // dispatch(getNotifications()),
        dispatch(getLists()),
        dispatch(getItems()),
        // dispatch(getVehicles()),
        dispatch(getHubs()),
        // dispatch(getAuditLogs()),
        // dispatch(getGeoLocation()),
        // dispatch(getCompanies()),
        // dispatch(getUsers()),
        dispatch(getDrivers()),
        dispatch(getRoles()),
        // dispatch(getPermission()),
        // dispatch(getTags()),
        // dispatch(getSources()),
    ]).then(() => {
        dispatch(setLoadingApp(false));
        dispatch(loadingSettings(false));
    });
};
