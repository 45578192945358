import {
    LOADING_CLIENTS,
    SET_CLIENT_PROFILE_SEARCH,
    SET_CLIENTS,
    SET_CURRENT_CLIENT,
    SET_LOADING_SEARCH
} from "../actions/types";

const initState = {
    listing: [],
    current: undefined,
    loading: true,
    search: [],
}

export const clientReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_CURRENT_CLIENT:
            return {...state, current: action.payload};
        case SET_CLIENTS:
            return {...state, listing: action.payload};
        case LOADING_CLIENTS:
            return {...state, loading: action.payload};
        case SET_LOADING_SEARCH:
            return {...state, loadingSearch: action.payload};
        case SET_CLIENT_PROFILE_SEARCH:
            return {...state, search: action.payload};
        default:
            return state;
    }
}







