/* Payments */
export const SET_PAYMENTS = "SET_PAYMENTS";
export const LOADING_PAYMENTS = "LOADING_PAYMENTS";

/* Client */
export const SET_CLIENTS = "SET_CLIENTS";
export const LOADING_CLIENTS = "LOADING_CLIENTS";
export const SET_CURRENT_CLIENT = "SET_CURRENT_CLIENT";
export const SET_CLIENT_PROFILE_SEARCH = "SET_CLIENT_PROFILE_SEARCH";
export const SET_LOADING_SEARCH = "SET_LOADING_SEARCH";

/* Logist */
export const SET_CALENDAR_POP_UP = "SET_CALENDAR_POP_UP";

/* Notifications */
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const LOADING_NOTIFICATIONS = "LOADING_NOTIFICATIONS";

/* Auth */
export const LOADING_APP = "LOADING_APP";
export const SET_AUTH_USER = "SET_AUTH_USER";
export const SET_AUTH_RESPONSE = "SET_AUTH_RESPONSE";

/* User */
export const LOADING_USER_SETTINGS = "LOADING_USER_SETTINGS";

/* SMS */
export const LOADING_SMS = "LOADING_SMS";
export const SET_DEVICES = "SET_DEVICES";
export const SET_CURRENT_DEVICE = "SET_CURRENT_DEVICE";
export const SET_CURRENT_CHAT = "SET_CURRENT_CHAT";
export const SET_CHAT = "SET_CHAT";
export const LOADING_SMS_CHAT = "LOADING_SMS_CHAT";

/* Ship It */
export const LOADING_SHIP_IT = "LOADING_SHIP_IT";
export const SET_ACTIVE_ROUTE_SHIP_IT = "SET_ACTIVE_ROUTE_SHIP_IT";
export const SET_DONOR_SHIP_IT = "SET_DONOR_SHIP_IT";

/* Orders */
export const SET_ORDERS = "SET_ORDERS";
export const LOADING_ORDERS = "LOADING_ORDERS";
export const LOADING_ORDER_UPDATE = "LOADING_ORDER_UPDATE";
export const LOADING_ORDER_THIRD_PARTY = "LOADING_ORDER_THIRD_PARTY";
export const LOADING_SEARCH_ORDER = "LOADING_SEARCH_ORDER";
export const SET_SEARCH_ORDER = "SET_SEARCH_ORDER";
export const SET_ORDER_FILTER = "SET_ORDER_FILTER";
export const SET_CURRENT_ORDER = "SET_CURRENT_ORDER";
export const SET_CURRENT_ORDER_WARNINGS = "SET_CURRENT_ORDER_WARNINGS";
export const LOADING_ORDER_VIEW = "LOADING_ORDER_VIEW";
export const LOADING_IS_DAMAGED = "LOADING_IS_DAMAGED";
export const NEW_ORDER_MODAL_SHOW_SUBMIT_AREA =
  "NEW_ORDER_MODAL_SHOW_SUBMIT_AREA";
export const NEW_ORDER_MODAL_SHOW_SUBMIT_BUTTON =
  "NEW_ORDER_MODAL_SHOW_SUBMIT_BUTTON";
export const SHOW_ORDER_PHOTOS_MODAL = "SHOW_ORDER_PHOTOS_MODAL";
export const CHECK_ORDER_CLIENT_PROFILE = "CHECK_ORDER_CLIENT_PROFILE";
export const SET_ORDER_LOGS = "SET_ORDER_LOGS";
export const LOADING_ORDER_LOGS = "LOADING_ORDER_LOGS";

/* Routes */
export const SET_ROUTES = "SET_ROUTES";
export const LOADING_ROUTES = "LOADING_ROUTES";
export const LOADING_ROUTE_VIEW_BUTTON = "LOADING_ROUTE_VIEW_BUTTON";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SLIDER_ORDER_DETAILS = "SET_SLIDER_ORDER_DETAILS";
export const SET_ROUTE_DAILY_FILTER = "SET_ROUTE_DAILY_FILTER";
export const SET_HIDDEN_ROUTES = "SET_HIDDEN_ROUTES";
export const SET_DRAG_N_DROP_FLAG = "SET_DRAG_N_DROP_FLAG";

/* Planner */
export const LOADING_PLANNER = "LOADING_PLANNER";
export const SET_PLANNED_CURRENT_ROUTE = "SET_PLANNED_CURRENT_ROUTE";
export const SET_PLANNED_ADVANCED_ROUTE = "SET_PLANNED_ADVANCED_ROUTE";
/* Station */
export const SHOW_PHOTO_MODAL_DRIVER = "SHOW_PHOTO_MODAL_DRIVER";

/* Settings */
export const LOADING_SETTINGS = "LOADING_SETTINGS";
export const SET_HUBS = "SET_HUBS";
export const SET_GEO_LOCATION = "SET_GEO_LOCATION";
export const SET_LOGS = "SET_LOGS";
export const SET_LISTS = "SET_LISTS";
export const SET_USERS = "SET_USERS";
export const SET_ROLES = "SET_ROLES";
export const SET_VEHICLES = "SET_VEHICLES";
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const SET_DIRECTIONS = "SET_DIRECTIONS";
export const SET_COMPANIES = "SET_COMPANIES";
export const SET_PAYMENT_ACCOUNTS = "SET_PAYMENT_ACCOUNTS";
export const SET_ITEMS = "SET_ITEMS";
export const SET_USER_ID_LOGS = "SET_USER_ID_LOGS";
export const SET_TAGS = "SET_TAGS";
export const SET_SOURCES = "SET_SOURCES";
export const SET_SETTINGS_DEVICES = "SET_SETTINGS_DEVICES";
export const SET_SETTINGS = "SET_SETTINGS";
export const SET_THEME = "SET_THEME";
export const SET_TASK_MANAGER = "SET_TASK_MANAGER";
export const SET_AREA_MANAGER = "SET_AREA_MANAGER";
export const SET_CHARGE_SERVICE_ZONES = "SET_CHARGE_SERVICE_ZONES";
export const SET_PRICE_TIER_MANAGER = "SET_PRICE_TIER_MANAGER";

/* Modals */
export const URL_MODAL = "URL_MODAL";
export const USER_MODAL = "USER_MODAL";
export const ROLE_MODAL = "ROLE_MODAL";
export const LOADING_MODAL = "LOADING_MODAL";
export const ADD_ITEM_MODAL = "ADD_ITEM_MODAL";
export const LIST_ITEM_MODAL = "LIST_ITEM_MODAL";
export const NEW_ORDER_MODAL = "NEW_ORDER_MODAL";
export const NEW_ORDER_MODALV2 = "NEW_ORDER_MODALV2";
export const NEW_ROUTE_MODAL = "NEW_ROUTE_MODAL";
export const NEW_CLIENT_MODAL = "NEW_CLIENT_MODAL";
export const PERMISSION_MODAL = "PERMISSION_MODAL";
export const CLONE_ORDER_MODAL = "CLONE_ORDER_MODAL";
export const ASSIGN_ORDER_MODAL = "ASSIGN_ORDER_MODAL";
export const SETTINGS_HUB_MODAL = "SETTINGS_HUB_MODAL";
export const DEPOSIT_ORDER_MODAL = "DEPOSIT_ORDER_MODAL";
export const INVOICE_ORDER_MODAL = "INVOICE_ORDER_MODAL";
export const ORDER_CONFIRM_MODAL = "ORDER_CONFIRM_MODAL";
export const ORDER_ORIGIN_MODAL = "ORDER_ORIGIN_MODAL";
export const SETTINGS_GEO_LOCATION_MODAL = "SETTINGS_GEO_LOCATION_MODAL";
export const SETTINGS_ITEM_MODAL = "SETTINGS_ITEM_MODAL";
export const SHOW_ROUTE_SMS_MODAL = "SHOW_ROUTE_SMS_MODAL";
export const ORDER_NOTIFICATION_MODAL = "ORDER_NOTIFICATION_MODAL";
export const ORDER_UPDATED_AT_MODAL = "ORDER_UPDATED_AT_MODAL";
export const SETTINGS_COMPANY_MODAL = "SETTINGS_COMPANY_MODAL";
export const SETTINGS_VEHICLE_MODAL = "SETTINGS_VEHICLE_MODAL";
export const SETTINGS_DIRECTION_MODAL = "SETTINGS_DIRECTION_MODAL";
export const SETTINGS_NOTIFICATION_MODAL = "SETTINGS_NOTIFICATION_MODAL";
export const SETTINGS_PAYMENT_ACCOUNT_MODAL = "SETTINGS_PAYMENT_ACCOUNT_MODAL";
export const SETTINGS_TAG_MODAL = "SETTINGS_TAG_MODAL";
export const SETTINGS_ORIGIN_MODAL = "SETTINGS_ORIGIN_MODAL";
export const SETTINGS_DEVICE_MODAL = "SETTINGS_DEVICE_MODAL";
export const PLANNER_MODAL = "PLANNER_MODAL";
export const QUICK_ASSIGN_ORDER_MODAL = "QUICK_ASSIGN_ORDER_MODAL";
export const CLIENT_VIEW_INVITE_MODAL = "CLIENT_VIEW_INVITE_MODAL";
export const ORDER_TRACKING_MODAL = "ORDER_TRACKING_MODAL";
export const ROUTE_DAILY_MERGE_PLAN_MODAL = "ROUTE_DAILY_MERGE_PLAN_MODAL";
export const ORDER_RECEIPT_MODAL = "ORDER_RECEIPT_MODAL";
export const ORDER_PAYMENT_REQUEST_MODAL = "ORDER_PAYMENT_REQUEST_MODAL";
export const SETTINGS_TASK_MANAGER_MODAL = "SETTINGS_TASK_MANAGER_MODAL";
export const SETTINGS_SERVICE_ZONE_MODAL = "SETTINGS_SERVICE_ZONE_MODAL";
export const SHOW_ORDER_EXTRAS_MODAL = "SHOW_ORDER_EXTRAS_MODAL";
export const SHOW_ORDER_CLIENT_PROFILE_MODAL =
  "SHOW_ORDER_CLIENT_PROFILE_MODAL";
export const SHOW_NEW_CLIENT_PROFILE_MODAL = "SHOW_NEW_CLIENT_PROFILE_MODAL";
export const SHOW_CLIENT_PROFILE_ATTACH_MODAL =
  "SHOW_CLIENT_PROFILE_ATTACH_MODAL";
export const PAY_THE_SELLER_MODAL = "PAY_THE_SELLER_MODAL";

/* UTILS */
export const SET_SETTINGS_MENU_ACTIVE_KEY = "SET_SETTINGS_MENU_ACTIVE_KEY";
export const LOADING_VIEW = "LOADING_VIEW";

/* REPORTS */
export const SET_REPORT_DATA = "SET_REPORT_DATA";

/* TASKS */
export const SET_TASK = "SET_TASK";
export const SET_TASKS = "SET_TASKS";
export const SET_LOADING_TASKS = "SET_LOADING_TASKS";
