import React, {Suspense, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentUser} from "../../actions/authService";

const Authentication = ({children, colorBgBase}) => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.auth.loading);

    useEffect(() => {
        dispatch(getCurrentUser());
    }, [dispatch]);

    return (
        <Suspense
            {...{loading}}
            fallback={
                <div
                    style={{
                        background: colorBgBase,
                        height: "100vh",
                    }}
                ></div>
            }
        >
            {React.Children.map(children, (Child) => Child)}
        </Suspense>
    );
};

export default Authentication;
