import {
    ADD_ITEM_MODAL,
    ASSIGN_ORDER_MODAL,
    CLIENT_VIEW_INVITE_MODAL,
    CLONE_ORDER_MODAL,
    DEPOSIT_ORDER_MODAL,
    INVOICE_ORDER_MODAL,
    LIST_ITEM_MODAL,
    LOADING_MODAL,
    NEW_CLIENT_MODAL,
    NEW_ORDER_MODAL,
    NEW_ORDER_MODAL_SHOW_SUBMIT_AREA,
    NEW_ORDER_MODAL_SHOW_SUBMIT_BUTTON,
    NEW_ORDER_MODALV2,
    NEW_ROUTE_MODAL,
    ORDER_NOTIFICATION_MODAL,
    ORDER_ORIGIN_MODAL,
    ORDER_PAYMENT_REQUEST_MODAL,
    ORDER_RECEIPT_MODAL,
    ORDER_TRACKING_MODAL,
    ORDER_UPDATED_AT_MODAL,
    PAY_THE_SELLER_MODAL,
    PERMISSION_MODAL,
    PLANNER_MODAL,
    QUICK_ASSIGN_ORDER_MODAL,
    ROLE_MODAL,
    ROUTE_DAILY_MERGE_PLAN_MODAL,
    SETTINGS_COMPANY_MODAL,
    SETTINGS_DEVICE_MODAL,
    SETTINGS_DIRECTION_MODAL,
    SETTINGS_GEO_LOCATION_MODAL,
    SETTINGS_HUB_MODAL,
    SETTINGS_ITEM_MODAL,
    SETTINGS_NOTIFICATION_MODAL,
    SETTINGS_ORIGIN_MODAL,
    SETTINGS_PAYMENT_ACCOUNT_MODAL,
    SETTINGS_SERVICE_ZONE_MODAL,
    SETTINGS_TAG_MODAL,
    SETTINGS_TASK_MANAGER_MODAL,
    SETTINGS_VEHICLE_MODAL,
    SHOW_CLIENT_PROFILE_ATTACH_MODAL,
    SHOW_NEW_CLIENT_PROFILE_MODAL,
    SHOW_ORDER_CLIENT_PROFILE_MODAL,
    SHOW_ORDER_EXTRAS_MODAL,
    SHOW_ORDER_PHOTOS_MODAL,
    SHOW_PHOTO_MODAL_DRIVER,
    SHOW_ROUTE_SMS_MODAL,
    URL_MODAL,
    USER_MODAL
} from "../actions/types";

const initState = {
    newItem: false,
    newOrder: false,
    newOrderSubmitArea: false,
    newOrderSubmitButton: false,
    loading: false,
    assignOrder: false,
    newRoute: false,
    newRole: false,
    listItemModal: false,
    routeSMSModel: false,
    orderUpdatedAtModal: false,
    settingsBankAccountModal: false,
    settingsTagModal: false,
    newClientModal: false,
    originModal: false,
    quickAssignOrderModal: false,
    deviceModal: false,
    clientViewInviteModal: false,
    orderTrackingModal: false,
    settingsTaskManagerModal: false,
    settingsServiceZonesModal: false,
    orderClientProfileModal: false,
    payTheSellerModal: false,
};

export const modalReducer = (state = initState, action) => {
    switch (action.type) {
        case ADD_ITEM_MODAL:
            return {...state, newItem: action.payload};
        case LOADING_MODAL:
            return {...state, loading: action.payload};
        case NEW_ORDER_MODAL:
            return {...state, newOrder: action.payload};
        case NEW_ORDER_MODAL_SHOW_SUBMIT_BUTTON:
            return {...state, newOrderSubmitButton: action.payload};
        case NEW_ORDER_MODAL_SHOW_SUBMIT_AREA:
            return {...state, newOrderSubmitArea: action.payload};
        case NEW_ROUTE_MODAL:
            return {...state, newRoute: action.payload};
        case USER_MODAL:
            return {...state, user: action.payload};
        case ROLE_MODAL:
            return {...state, role: action.payload};
        case PERMISSION_MODAL:
            return {...state, permission: action.payload};
        case ASSIGN_ORDER_MODAL:
            return {...state, assignOrder: action.payload};
        case DEPOSIT_ORDER_MODAL:
            return {...state, depositModal: action.payload};
        case INVOICE_ORDER_MODAL:
            return {...state, invoiceModal: action.payload};
        case CLONE_ORDER_MODAL:
            return {...state, cloneModal: action.payload};
        case SHOW_PHOTO_MODAL_DRIVER:
            return {...state, showPhotoDriverModal: action.payload};
        case LIST_ITEM_MODAL:
            return {...state, listItemModal: action.payload};
        case SHOW_ROUTE_SMS_MODAL:
            return {...state, routeSMSModel: action.payload};
        case SETTINGS_GEO_LOCATION_MODAL:
            return {...state, settingsUlezModal: action.payload};
        case SETTINGS_COMPANY_MODAL:
            return {...state, settingsCompanyModal: action.payload};
        case SETTINGS_HUB_MODAL:
            return {...state, settingsHubModal: action.payload};
        case SETTINGS_DIRECTION_MODAL:
            return {...state, settingsDirectionModal: action.payload};
        case SETTINGS_VEHICLE_MODAL:
            return {...state, settingsVehicleModal: action.payload};
        case SETTINGS_ITEM_MODAL:
            return {...state, settingsItemModal: action.payload};
        case ORDER_UPDATED_AT_MODAL:
            return {...state, orderUpdatedAtModal: action.payload};
        case URL_MODAL:
            return {...state, urlModal: action.payload};
        case ORDER_NOTIFICATION_MODAL:
            return {...state, orderNotificationModal: action.payload};
        case SETTINGS_NOTIFICATION_MODAL:
            return {...state, settingsNotificationModal: action.payload};
        case SETTINGS_PAYMENT_ACCOUNT_MODAL:
            return {...state, settingsPaymentAccountModal: action.payload};
        case SETTINGS_TAG_MODAL:
            return {...state, settingsTagModal: action.payload};
        case SETTINGS_DEVICE_MODAL:
            return {...state, settingsDeviceModal: action.payload};
        case PLANNER_MODAL:
            return {...state, plannerModal: action.payload};
        case NEW_CLIENT_MODAL:
            return {...state, newClientModal: action.payload};
        case NEW_ORDER_MODALV2:
            return {...state, newOrderV2: action.payload};
        case SETTINGS_ORIGIN_MODAL:
            return {...state, originModal: action.payload};
        case ORDER_ORIGIN_MODAL:
            return {...state, orderOriginModal: action.payload};
        case QUICK_ASSIGN_ORDER_MODAL:
            return {...state, quickAssignOrderModal: action.payload};
        case CLIENT_VIEW_INVITE_MODAL:
            return {...state, clientViewInviteModal: action.payload};
        case ORDER_TRACKING_MODAL:
            return {...state, orderTrackingModal: action.payload};
        case SHOW_ORDER_PHOTOS_MODAL:
            return {...state, orderPhotosModal: action.payload};
        case ROUTE_DAILY_MERGE_PLAN_MODAL:
            return {...state, routeDailyMergePlanModal: action.payload};
        case ORDER_RECEIPT_MODAL:
            return {...state, orderReceiptModal: action.payload};
        case ORDER_PAYMENT_REQUEST_MODAL:
            return {...state, orderPaymentRequestModal: action.payload};
        case SETTINGS_TASK_MANAGER_MODAL:
            return {...state, settingsTaskManagerModal: action.payload};
        case SETTINGS_SERVICE_ZONE_MODAL:
            return {...state, settingsServiceZonesModal: action.payload};
        case SHOW_ORDER_EXTRAS_MODAL:
            return {...state, orderExtrasModal: action.payload};
        case SHOW_ORDER_CLIENT_PROFILE_MODAL:
            return {...state, orderClientProfileModal: action.payload};
        case SHOW_NEW_CLIENT_PROFILE_MODAL:
            return {...state, showNewClientProfileModal: action.payload};
        case SHOW_CLIENT_PROFILE_ATTACH_MODAL:
            return {...state, showClientProfileAttachModal: action.payload};
        case PAY_THE_SELLER_MODAL:
            return {...state, payTheSeller: action.payload};
        default:
            return state;
    }
};
