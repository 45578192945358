import {
  LOADING_ROUTE_VIEW_BUTTON,
  LOADING_ROUTES,
  SET_CURRENT_ROUTE,
  SET_DRAG_N_DROP_FLAG,
  SET_HIDDEN_ROUTES,
  SET_ROUTE_DAILY_FILTER,
  SET_ROUTES,
  SET_SLIDER_ORDER_DETAILS
} from "../actions/types";

const initState = {
  listing: [],
  loading: true,
  loadingRouteViewButton: false,
  current: null,
  sliderDetails: null,
  routeDailyFilter: null,
  hiddenRoutes: [],
  dragNDropFlag: false
};

export const routeReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_CURRENT_ROUTE:
      return { ...state, current: action.payload };
    case SET_ROUTES:
      return { ...state, lodagin: false, listing: action.payload };
    case LOADING_ROUTES:
      return { ...state, loading: action.payload };
    case SET_SLIDER_ORDER_DETAILS:
      return { ...state, sliderDetails: action.payload };
    case SET_ROUTE_DAILY_FILTER:
      return { ...state, routeDailyFilter: action.payload };
    case LOADING_ROUTE_VIEW_BUTTON:
      return { ...state, loadingRouteViewButton: action.payload };
    case SET_HIDDEN_ROUTES:
      return { ...state, hiddenRoutes: action.payload };
    case SET_DRAG_N_DROP_FLAG:
      return { ...state, dragNDropFlag: action.payload };
    default:
      return state;
  }
};
