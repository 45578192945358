import React, { lazy, startTransition, Suspense } from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import reduxThunk from "redux-thunk";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import reducers from "./reducers";
import { setInterceptors } from "./actions/axiosService";
import { ConfigProvider } from "antd";
import moment from "moment";
import "antd/dist/reset.css";
import updateLocale from "dayjs/plugin/updateLocale";
import dayjs from "dayjs";
import "./styles/index.less";
import { AwsRum } from "aws-rum-web";
// eslint-disable-next-line no-unused-vars
import { darkTheme, lightTheme } from "./consts";
import Authentication from "./components/auth";
import * as Sentry from "@sentry/react";

const Office = lazy(() => import("./components"));

var utc = require("dayjs/plugin/utc");

const composeEnhancers =
  (process.env.NODE_ENV !== "production" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
);
dayjs.extend(utc);
dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  weekStart: 1
});

const initRum = () => {
  try {
    const config = {
      sessionSampleRate: 1,
      identityPoolId: "eu-central-1:c408fdd5-fb02-4e9e-8c0b-e546d0b391e4",
      endpoint: "https://dataplane.rum.eu-central-1.amazonaws.com",
      telemetries: ["performance", "errors", "http"],
      allowCookies: true,
      enableXRay: false
    };
    const APPLICATION_ID = "b9fccd93-cd5a-44cd-92b7-570e2ba57914";
    const APPLICATION_VERSION = "1.0.0";
    const APPLICATION_REGION = "eu-central-1";
    // eslint-disable-next-line no-unused-vars
    const awsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    );
  } catch (error) {
    console.error("RUM initialization error", error);
  }
};

const initSentry = () => {
  Sentry.init({
    dsn: "https://1af1e93c91f3042f8723b4bee474f4ec@o4508630328082432.ingest.de.sentry.io/4508630341714000",
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    tracePropagationTargets: ["localhost", /^https:\/\/admin\.seagoat\.app/]
  });
};

const AppWrapper = () => {
  if (process.env.NODE_ENV === "production") {
    initRum();
    initSentry();
  }

  moment.locale("en-gb", {
    week: {
      dow: 1
    }
  });

  ConfigProvider.config({
    prefixCls: "ant", // 4.13.0+
    iconPrefixCls: "anticon" // 4.17.0+
  });

  store.dispatch(setInterceptors());

  const theme = JSON.parse(localStorage.getItem("appSettings"))?.theme;

  let mytheme = {};

  if (theme === "dark") {
    mytheme = darkTheme;
  } else if (theme === "light") {
    mytheme = lightTheme;
  }

  return (
    <Suspense
      fallback={
        <div
          style={{ background: mytheme?.token?.colorBgBase, height: "100vh" }}
        ></div>
      }
    >
      <Provider store={store}>
        <Authentication {...{ colorBgBase: mytheme?.token?.colorBgBase }}>
          <Office />
        </Authentication>
      </Provider>
    </Suspense>
  );
};

startTransition(() => {
  ReactDOM.render(<AppWrapper />, document.getElementById("root"));
});

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log(
          "Service Worker registered with scope:",
          registration.scope
        );

        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (installingWorker) {
            installingWorker.onstatechange = () => {
              if (installingWorker.state === "installed") {
                if (navigator.serviceWorker.controller) {
                  const isUpdate = window.confirm(
                    "A new version is available. Do you want to update?"
                  );
                  if (isUpdate) {
                    registration.waiting.postMessage({ type: "SKIP_WAITING" });
                    window.location.reload();
                  }
                }
              }
            };
          }
        };
      })
      .catch((error) =>
        console.error("Service Worker registration failed:", error)
      );
  });
}
