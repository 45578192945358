import {combineReducers} from "redux";
import {reducer as formReducer} from "redux-form";
import {modalReducer} from "./modalReducer";
import {orderReducer} from "./orderReducer";
import {routeReducer} from "./routeReducer";
import {settingsReducer} from "./settingsReducer";
import {authReducer} from "./authReducer";
import {logistReducer} from "./logistReducer";
import {clientReducer} from "./clientReducer";
import {notificationReducer} from "./notificationReducer";
import {smsReducer} from "./smsReducer";
import {utilsReducer} from "./utilsReducer";
import {plannerReducer} from "./plannerReducer";
import {reportReducer} from "./reportReducer";
import {taskReducer} from "./taskReducer";
import {paymentReducer} from "./paymetReducer";

const rootReducer = combineReducers({
    form: formReducer,
    auth: authReducer,
    order: orderReducer,
    route: routeReducer,
    modal: modalReducer,
    settings: settingsReducer,
    logist: logistReducer,
    planner: plannerReducer,
    client: clientReducer,
    notifications: notificationReducer,
    sms: smsReducer,
    utils: utilsReducer,
    report: reportReducer,
    task: taskReducer,
    payment: paymentReducer,
});

export default rootReducer;
