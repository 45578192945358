import {LOADING_APP, SET_AUTH_RESPONSE, SET_AUTH_USER} from "../actions/types";

const initState = {
    current: undefined,
    loading: true,
}

export const authReducer = (state = initState, action) => {
    switch (action.type) {
        case LOADING_APP:
            return {...state, loading: action.payload};
        case SET_AUTH_USER:
            return {...state, current: action.payload}
        case SET_AUTH_RESPONSE:
            return {...state, response: action.payload}
        default:
            return state;
    }
}







