import {
    CHECK_ORDER_CLIENT_PROFILE,
    LOADING_IS_DAMAGED,
    LOADING_ORDER_LOGS,
    LOADING_ORDER_THIRD_PARTY,
    LOADING_ORDER_UPDATE,
    LOADING_ORDERS,
    LOADING_SEARCH_ORDER,
    SET_CURRENT_ORDER,
    SET_CURRENT_ORDER_WARNINGS,
    SET_ORDER_FILTER,
    SET_ORDER_LOGS,
    SET_ORDERS,
    SET_SEARCH_ORDER
} from "../actions/types";

const initState = {
    listing: undefined,
    warnings: {},
    loading: true,
    loadingIsDamaged: false,
    loadingOrderUpdate: false,
    current: null,
    filters: {},
    activeOrderFilter: {},
    checkProfile: null,
    logs: [],
    loadingLogs: false,
    loadingSearchOrder: false,
    searchOrder: null,
    loadingOrderThirdParty: false,
};

export const orderReducer = (state = initState, action) => {
    switch (action.type) {
        case LOADING_ORDER_LOGS:
            return {...state, loadingLogs: action.payload};
        case SET_ORDER_LOGS:
            return {...state, logs: action.payload};
        case SET_CURRENT_ORDER:
            return {...state, current: action.payload};
        case SET_ORDER_FILTER:
            return {...state, filters: action.payload};
        case SET_ORDERS:
            return {...state, listing: action.payload};
        case LOADING_SEARCH_ORDER:
            return {...state, loadingSearchOrder: action.payload};
        case SET_SEARCH_ORDER:
            return {...state, searchOrder: action.payload};
        case LOADING_ORDERS:
            return {...state, loading: action.payload};
        case LOADING_IS_DAMAGED:
            return {...state, loadingIsDamaged: action.payload};
        case LOADING_ORDER_UPDATE:
            return {...state, loadingOrderUpdate: action.payload};
        case LOADING_ORDER_THIRD_PARTY:
            return {...state, loadingOrderThirdParty: action.payload};
        case SET_CURRENT_ORDER_WARNINGS:
            return {...state, warnings: action.payload};
        case CHECK_ORDER_CLIENT_PROFILE:
            return {...state, checkProfile: action.payload};
        default:
            return state;
    }

};
