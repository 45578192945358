import {SET_REPORT_DATA} from "../actions/types";


const initState = {
    data: [],
}


export const reportReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_REPORT_DATA:
            return {...state, data: action.payload};
        default:
            return state;
    }
}