import {LOADING_VIEW, SET_SETTINGS_MENU_ACTIVE_KEY} from "../actions/types";

const initState = {
    settingsMenuActiveKey: ['1'],
}

export const utilsReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_SETTINGS_MENU_ACTIVE_KEY:
            return {...state, settingsMenuActiveKey: action.payload}
        case LOADING_VIEW:
            return {...state, loadingView: action.payload}
        default:
            return state;
    }
}







