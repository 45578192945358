import {LOADING_NOTIFICATIONS, SET_NOTIFICATIONS} from "../actions/types";

const initState = {
    route_confirmation: [],
    loading: false,
};

export const notificationReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_NOTIFICATIONS:
            return {...state, ...action.payload};
        case LOADING_NOTIFICATIONS:
            return {...state, loading: action.payload};
        default:
            return state;
    }
};
